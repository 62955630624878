import React, { Fragment, useState, useRef, useEffect, useCallback } from "react";
import { Link } from "gatsby";
import * as styles from "./Services.module.scss";
import slugify from "../../../helpers/index";

const Services = ({ title, visible, services }: GatsbyTypes.StrapiComponentLandingServices | any) => {
    const [current, setCurrent] = useState(0);
    const navWrapper = useRef<any>(null);
    const navItems = useRef<any>([]);
    const [trackProperties, setTrackProperties] = useState<any>({});

    if (!services) services = [];

    if (services.length === 0) {
        services.push({
            ourServices: {
                heading: "Service",
                subHeading: "Service",
                imageSVG: "",
            },
            name: "Service",
            callout: "Default",
            image: null,
        });
    }

    let { heading, subHeading } = services[current].ourServices;

    const setNavTrack = useCallback(() => {
        let activeItem;

        for (let i = 0; i < navItems.current.length; i++) {
            if (i === current) {
                activeItem = navItems.current[i];
            }
        }

        let { top, left, width } = activeItem?.getBoundingClientRect();
        let { top: navTop, left: navLeft } = navWrapper.current?.getBoundingClientRect();

        setTrackProperties({
            trackLeft: left - navLeft,
            trackWidth: width,
            trackTop: top - navTop,
        });
    }, [current]);

    useEffect(() => {
        setNavTrack();
    }, [current, setNavTrack]);

    return (
        <section className={`${styles.wrapper} px-4`}>
            <div className="mainBox">
                <div className={styles.inner}>
                    <h2 className="font-serif">{title}</h2>

                    <nav
                        className={styles.nav}
                        ref={navWrapper}
                        style={{
                            ["--trackLeft" as string]: trackProperties.trackLeft,
                            ["--trackWidth" as string]: trackProperties.trackWidth,
                            ["--trackTop" as string]: trackProperties.trackTop,
                        }}>
                        {services.map((service: any, i: number) => (
                            <button
                                key={`${slugify(service.ourServices.heading)}`}
                                className={i === current ? styles.isActive : ""}
                                onClick={() => setCurrent(i)}
                                ref={el => (navItems.current[i] = el)}>
                                {service.ourServices.heading}
                            </button>
                        ))}
                        <div className={styles.navTrack} />
                    </nav>

                    <div className={styles.main}>
                        <div className={styles.svgContainer}>
                            <div>
                                <svg className={styles.backgroundSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460 450">
                                    <path
                                        fill="#DDDDDD"
                                        d="M459.39,400.77c-8.99,45.3-115.02,62.42-236.81,38.25C100.78,414.84-8.47,353.1,0.52,307.8
                                        c5.16-25.97,29.32-82.3,82.96-87.28c39.91-3.7,119.69,44.14,171.65,54.45C376.93,299.15,468.38,355.47,459.39,400.77z"
                                    />
                                    <path
                                        fill="none"
                                        stroke="#152640"
                                        strokeMiterlimit="10"
                                        d="M421.8,412.36c-0.69-1.31-2.39-1.85-3.71-1.08l-6.87,3.7V250.66c0-1.7-1.39-3.09-3.09-3.09
                                        s-3.09,1.39-3.09,3.09v133.01L108.91,256.68V120.82c0-1.7-1.39-3.09-3.09-3.09c-1.7,0-3.09,1.39-3.09,3.09v174.28l-7.96,4.32
                                        c-1.31,0.69-1.85,2.39-1.08,3.7c0.77,1.31,2.39,1.85,3.71,1.08l21.94-11.96c1.31-0.69,1.85-2.39,1.08-3.7
                                        c-0.77-1.31-2.39-1.85-3.71-1.08l-7.8,4.24v-24.84l296.13,126.99v24.46l-8.96,4.86c-1.31,0.69-1.85,2.39-1.08,3.7
                                        s2.39,1.85,3.71,1.08l21.94-11.96C422.03,415.29,422.5,413.67,421.8,412.36z"
                                    />
                                    <path fill="#B74B41" d="M105.82,0l302.31,129.38v215.71L105.82,215.71V0z" />
                                    <path fill="#FFFFFF" stroke="#152640" strokeMiterlimit="10" d="M99.64,4.55l302.31,129.46v215.71L99.64,220.26V4.55z" />
                                </svg>

                                {services.map((service: any, i: number) => (
                                    <span key={i} className={current !== i ? "leave" : ""} dangerouslySetInnerHTML={{ __html: service.ourServices.imageSVG }} />
                                ))}
                            </div>
                        </div>

                        <Fragment key={current}>
                            <h3>{heading}</h3>
                            <p className={styles.callout}>{subHeading}</p>
                            <Link to={`/services/${slugify(services[current].ourServices.heading)}`} className={styles.link}>
                                <span>Learn more</span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.62 21.79">
                                        <polygon points="41.12 0 38.99 2.12 45.99 9.12 0 9.12 0 12.12 45.77 12.12 38.22 19.67 40.34 21.79 51.62 10.51 41.12 0"></polygon>
                                    </svg>
                                </span>
                            </Link>
                        </Fragment>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
