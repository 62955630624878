import React, { useState, useEffect, useRef } from "react";
import InteractiveLogo from "./InteractiveLogo/InteractiveLogo";
import * as styles from "./Hero.module.scss";

const taglineTransitionDelay = 2000;
const animationTransitionSpeed = 500;

const Hero = ({ headingIntro, headingTagline, subHeading }: GatsbyTypes.StrapiComponentLandingHero) => {
    const [currentTagline, setCurrentTagline] = useState(headingTagline[0]);
    const currentTaglineIndex = useRef(0);
    const taglineRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            currentTaglineIndex.current++;

            if (currentTaglineIndex.current === headingTagline.length) currentTaglineIndex.current = 0;

            setCurrentTagline(headingTagline[currentTaglineIndex.current]);
        }, taglineTransitionDelay);

        return () => clearInterval(interval);
    }, [headingTagline]);

    useEffect(() => {
        if (!taglineRef.current) return;
        taglineRef.current.classList.remove(styles.hide);
        taglineRef.current.classList.add(styles.show);

        const timeout = setTimeout(() => {
            if (!taglineRef.current) return;
            taglineRef.current.classList.remove(styles.show);
            taglineRef.current.classList.add(styles.hide);
        }, taglineTransitionDelay - animationTransitionSpeed);

        return () => clearTimeout(timeout);
    }, [currentTagline]);

    return (
        <section className={styles.wrapper}>
            <InteractiveLogo />

            <div className={`${styles.content} mainBox`}>
                <div className={styles.inner}>
                    <h1>
                        <span>{headingIntro}</span>
                        <span ref={taglineRef} className={styles.tagline}>
                            {currentTagline?.tagline}
                        </span>
                    </h1>
                    <p className={styles.subHeading}>{subHeading}</p>
                </div>
            </div>
        </section>
    );
};
export default Hero;
