import React from "react";
import { navigate } from "gatsby";
import * as styles from "./Contact.module.scss";
//import EventTrackerService from "../../../services/EventTrackerService";

const Contact = ({ heading, paragraph, cta, link }: any) => {
    const handleClick = (link: string, buttonName: string) => {
        //EventTrackerService.buttonClick(buttonName);
        navigate(`${link}`);
    };

    return (
        <section className={`${styles.wrapper} px-4`}>
            <div className="mainBox">
                <h3>{heading}</h3>
                <p>{paragraph}</p>
                <button className="button" onClick={() => handleClick(link, cta)}>
                    {cta}
                </button>
            </div>
        </section>
    );
};

export default Contact;
