import React from "react";
import BannerText from "../../../components/Shared/BannerText";
import Contact from "../Contact/Contact";
import Hero from "../Hero/Hero";
import Services from "../Services/Services";

interface IWidgetFactoryProps {
    widget: IstrapyWidgets & GatsbyTypes.StrapiLandingPageContentDynamicZone;
}

export interface IstrapyWidgets {
    __typename: string;
}

function WidgetFactory({ widget }: IWidgetFactoryProps) {
    const { __typename } = widget;
    switch (__typename) {
        case "StrapiComponentLandingHero":
            return <Hero {...{ ...(widget as GatsbyTypes.StrapiComponentLandingHero) }} />;
        case "StrapiComponentLandingBanner":
            return <BannerText {...{ ...(widget as GatsbyTypes.StrapiComponentLandingBanner) }} hasUnderline={true} />;
        case "StrapiComponentLandingServices":
            return <Services {...{ ...(widget as GatsbyTypes.StrapiComponentLandingServices) }} />;

        case "StrapiComponentLandingContact":
            return <Contact {...{ ...(widget as GatsbyTypes.StrapiComponentLandingContact) }} />;
        default:
            console.error(`This component named ${__typename} doesn't exist on landing gatsby components`);
            return null;
    }
}

export default WidgetFactory;
