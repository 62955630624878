import React from "react";
import WidgetFactory, { IstrapyWidgets } from "./WidgetFactory/WidgetFactory";

const LandingPageTemplate = (props: GatsbyTypes.StrapiLandingPage) => {
    const { content } = props;
    return (
        <div>
            {content &&
                content.map((contentWidget, i) => {
                    if (!contentWidget) return null;
                    return (
                        <WidgetFactory widget={contentWidget as IstrapyWidgets & GatsbyTypes.StrapiLandingPageContentDynamicZone} key={`landing-widget-${i}`} />
                    );
                })}
        </div>
    );
};
export default LandingPageTemplate;