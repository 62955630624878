import React, { useRef, useEffect, useCallback } from "react";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import * as styles from "./InteractiveLogo.module.scss";

const InteractiveLogo = () => {
    const svgWrapper = useRef<SVGSVGElement>(null);
    const mainContainer = useRef<HTMLDivElement>(null);
    const counter = useRef<number>(0);
    const globalPos = useRef<any>();
    const isDesktop = useWindowWidth(1200);

    let updateRate = 4;
    let isReady = () => counter.current % updateRate === 0;

    const handleOrientationChange = useCallback(
        (e: any) => {
            !isDesktop && performParallax(e.gamma * 20, 0);
        },
        [isDesktop]
    );

    useEffect(() => {
        globalPos.current = {
            centerX: window.innerWidth / 2,
            centerY: window.innerHeight / 2,
        };

        // adding this event handler here, to avoid a re-render.
        window.addEventListener("deviceorientation", handleOrientationChange, true);
        return () => window.removeEventListener("deviceorientation", handleOrientationChange);
    }, [handleOrientationChange]);

    function handleMouseEnter(e: any) {
        document.documentElement.style.setProperty("--svgWrapperTransition", "transform linear 100ms");

        if (!svgWrapper.current) return;
        for (let layer of svgWrapper.current.children as any) {
            layer.style.transition = "transform linear 100ms";
        }

        performParallax(e.clientX, e.clientY);
    }

    function handleMouseMove(e: any) {
        counter.current++;
        if (isReady()) performParallax(e.clientX, e.clientY);
    }

    function handleMouseLeave() {
        resetStyles();
    }

    function performParallax(x: number, y: number) {
        let translateDepth = 0.01;

        document.documentElement.style.setProperty("--svgWrapperTransform", `rotateY(${(x - globalPos.current?.centerX) * 0.0005}deg)`);

        {
            if (!svgWrapper.current) return;
            for (let layer of svgWrapper.current.children as any) {
                layer.style.transform = `translate3d(${(x - globalPos.current?.centerX) * translateDepth}%, ${
                    (y - globalPos.current?.centerY) * translateDepth
                }%, 0)`;
                translateDepth += 0.005;
            }
        }
    }

    function resetStyles() {
        document.documentElement.style.setProperty("--svgWrapperTransform", "rotateY(0deg)");
        document.documentElement.style.setProperty("--svgWrapperTransition", "transform ease 250ms");

        if (!svgWrapper.current) return;
        for (let layer of svgWrapper.current.children as any) {
            layer.style.transition = "transform ease 250ms";
            layer.style.transform = "translate3d(0, 0, 0)";
        }
    }

    return (
        <div
            className={styles.main}
            ref={mainContainer}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            role="presentation">
            <div className={styles.inner}>
                <svg className={styles.svgWrapper} ref={svgWrapper} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 375">
                    <g className={styles.animLayer}>
                        <polygon className={styles.st0} points="243.5,126.06 143.37,52.91 143.37,158.5" />
                        <path className={styles.st0} d="M513.47,52.91l-370.1,119.9l0,145.1c0,2.19,2.68,3.43,4.53,2.08L513.47,52.91L513.47,52.91z" />
                        <path className={styles.st0} d="M513.46,69.97L326.73,327.51c-0.54,0.74-1.66,0.71-2.15-0.07l-51.91-81.57L513.46,69.97z" />
                        <path className={styles.st0} d="M404.32,243.54l104.62,76.43c1.84,1.35,4.53,0.11,4.53-2.08V92.99L404.32,243.54z" />
                    </g>
                    <g className={styles.animLayer}>
                        <polyline className={styles.st1} points="589.74,363.75 589.74,369.75 583.74,369.75" />
                        <line className={styles.st2} x1="571.83" y1="369.75" x2="101.26" y2="369.75" />
                        <polyline className={styles.st1} points="95.3,369.75 89.3,369.75 89.3,363.75" />
                        <line className={styles.st3} x1="89.3" y1="351.96" x2="89.3" y2="27.65" />
                        <polyline className={styles.st1} points="89.3,21.76 89.3,15.76 95.3,15.76" />
                        <line className={styles.st2} x1="107.22" y1="15.76" x2="577.78" y2="15.76" />
                        <polyline className={styles.st1} points="583.74,15.76 589.74,15.76 589.74,21.76" />
                        <line className={styles.st3} x1="589.74" y1="33.55" x2="589.74" y2="357.86" />
                        <rect x="84.35" y="10.8" className={styles.st4} width="9.91" height="9.91" />
                        <rect x="84.35" y="364.8" className={styles.st4} width="9.91" height="9.91" />
                        <rect x="584.78" y="364.8" className={styles.st4} width="9.91" height="9.91" />
                        <rect x="584.78" y="10.8" className={styles.st4} width="9.91" height="9.91" />
                    </g>
                    <g className={styles.animLayer}>
                        <polygon className={styles.st6} points="251.93,102.56 151.79,29.41 151.79,135" />
                        <path className={styles.st6} d="M521.9,29.41l-370.1,119.9l0,145.1c0,2.19,2.68,3.43,4.53,2.08L521.9,29.41L521.9,29.41z" />
                        <path className={styles.st6} d="M521.88,46.47L335.16,304.01c-0.54,0.74-1.66,0.71-2.15-0.07l-51.91-81.57L521.88,46.47z" />
                        <path className={styles.st6} d="M412.74,220.04l104.62,76.43c1.84,1.35,4.53,0.11,4.53-2.08V69.49L412.74,220.04z" />
                    </g>
                    <g className={styles.animLayer}>
                        <path className={styles.st5} d="M529.47,318.72c1.08-0.33,1.94-1.27,1.94-2.51V91.3l-1.94,2.67V318.72z" />
                        <polygon className={styles.st5} points="445.33,210.03 443.39,212.7 443.39,257.3 445.33,258.72" />
                        <polygon className={styles.st5} points="437.51,220.82 435.57,223.5 435.57,251.59 437.51,253" />
                        <polygon className={styles.st5} points="453.16,199.24 451.22,201.91 451.22,263.02 453.16,264.44" />
                        <polygon className={styles.st5} points="429.68,247.28 429.68,231.62 427.74,234.29 427.74,245.87" />
                        <polygon className={styles.st5} points="468.81,177.65 466.87,180.32 466.87,274.45 468.81,275.87" />
                        <polygon className={styles.st5} points="460.98,188.44 459.04,191.12 459.04,268.74 460.98,270.15" />
                        <polygon className={styles.st5} points="476.63,166.86 474.69,169.53 474.69,280.17 476.63,281.59" />
                        <polygon className={styles.st5} points="492.28,145.27 490.34,147.94 490.34,291.6 492.28,293.02" />
                        <polygon className={styles.st5} points="523.58,102.09 521.65,104.77 521.65,314.47 523.58,315.89" />
                        <polygon className={styles.st5} points="500.11,134.48 498.17,137.15 498.17,297.32 500.11,298.74" />
                        <polygon className={styles.st5} points="484.46,156.06 482.52,158.74 482.52,285.89 484.46,287.3" />
                        <polygon className={styles.st5} points="515.76,112.89 513.82,115.56 513.82,308.75 515.76,310.17" />
                        <polygon className={styles.st5} points="507.93,123.68 505.99,126.36 505.99,303.04 507.93,304.45" />
                        <polygon className={styles.st5} points="244.1,96.93 246.3,96.22 246.3,53.58 244.1,51.98" />
                        <polygon className={styles.st5} points="235.21,99.81 237.42,99.1 237.42,47.09 235.21,45.48" />
                        <polygon className={styles.st5} points="217.44,105.57 219.64,104.85 219.64,34.1 217.44,32.5" />
                        <polygon className={styles.st5} points="226.32,102.69 228.53,101.98 228.53,40.6 226.32,38.99" />
                        <polygon className={styles.st5} points="208.55,108.45 210.75,107.73 210.75,27.61 208.55,26" />
                        <polygon className={styles.st5} points="199.66,111.33 201.86,110.61 201.86,21.12 199.66,19.51" />
                        <polygon className={styles.st5} points="281.86,79.56 279.66,77.95 279.66,85.41 281.86,84.7" />
                        <polygon className={styles.st5} points="252.99,94.05 255.19,93.34 255.19,60.08 252.99,58.47" />
                        <polygon className={styles.st5} points="270.77,88.29 272.97,87.58 272.97,73.07 270.77,71.46" />
                        <polygon className={styles.st5} points="261.88,91.17 264.08,90.46 264.08,66.57 261.88,64.96" />
                        <polygon className={styles.st5} points="190.77,114.21 192.97,113.49 192.97,14.62 190.77,13.01" />
                        <polygon className={styles.st5} points="181.88,117.08 184.08,116.37 184.08,8.13 181.88,6.52" />
                        <polygon className={styles.st5} points="175.19,1.63 172.99,0.03 172.99,119.96 175.19,119.25" />
                    </g>
                    <g className={styles.animLayer}>
                        <path
                            className={`${styles.st7} ${styles.st8}`}
                            d="M512.68,44.37l-370.12,119.9l0,145.1c0,2.19,2.68,3.43,4.53,2.08L512.68,44.37L512.68,44.37z"
                        />
                        <path
                            className={`${styles.st7} ${styles.st8}`}
                            d="M512.67,61.43L325.94,318.99c-0.54,0.74-1.66,0.71-2.15-0.07l-51.91-81.58L512.67,61.43z"
                        />
                    </g>
                    <g className={styles.animLayer}>
                        <rect x="97.29" y="86.39" className={styles.st9} width="23.26" height="7.37" />
                        <rect x="127.91" y="86.39" className={styles.st10} width="23.26" height="7.37" />
                        <rect x="76.56" y="69.19" className={styles.st9} width="128.47" height="7.37" />
                        <rect x="93.57" y="51.98" className={styles.st9} width="40.67" height="7.37" />
                        <rect x="46.27" y="51.98" className={styles.st10} width="40.67" height="7.37" />
                        <rect x="3.05" y="34.78" className={styles.st9} width="88.01" height="7.37" />
                        <rect x="97.29" y="34.78" className={styles.st10} width="88.01" height="7.37" />
                        <rect x="3.05" y="17.57" className={styles.st9} width="19.59" height="7.37" />
                        <rect x="28.81" y="17.57" className={styles.st10} width="19.59" height="7.37" />
                        <rect x="56.81" y="17.57" className={styles.st9} width="87.25" height="7.37" />
                        <rect x="95.69" y="0.37" className={styles.st9} width="113.39" height="7.37" />
                        <rect x="45.42" y="0.37" className={styles.st10} width="42.37" height="7.37" />
                        <rect x="3.05" y="0.37" className={styles.st9} width="32.89" height="7.37" />
                    </g>
                    <g className={styles.animLayer}>
                        <path className={styles.st11} d="M642.17,300.38" />
                        <path className={styles.st0} d="M654.67,242.09" />
                        <circle className={styles.st0} cx="629.88" cy="270.15" r="66.67" />
                        <path
                            className={styles.st9}
                            d="M640.24,297.34h-20.31c-0.78,0-1.41,0.63-1.41,1.41v3.28c0,0.78,0.63,1.41,1.41,1.41h20.31
                            c0.78,0,1.41-0.63,1.41-1.41v-3.28C641.65,297.96,641.02,297.34,640.24,297.34z"
                        />
                        <path
                            className={styles.st9}
                            d="M633.29,252.56c-0.39-0.7-1.02-1.25-1.72-1.56v16.8c1.72,0.63,2.89,2.27,2.89,4.14
                            c0,2.42-1.95,4.38-4.38,4.38c-2.42,0-4.38-1.95-4.38-4.38c0-1.88,1.25-3.52,2.89-4.14v-16.72c-0.7,0.31-1.25,0.86-1.72,1.48
                            l-10.47,18.28c-0.7,1.17-0.94,2.58-0.63,3.91l2.5,16.48c0.16,0.63,0.7,1.17,1.41,1.17h20.86c0.7,0,1.25-0.47,1.41-1.17l2.5-16.48
                            c0.31-1.33,0.08-2.73-0.63-3.91L633.29,252.56z"
                        />
                        <path
                            className={styles.st12}
                            d="M663.37,244.43c2.11,0,3.83-1.72,3.83-3.83l0,0c0-2.11-1.72-3.83-3.83-3.83c-1.64,0-3.05,1.09-3.59,2.58
                            h-25.63c-0.08,0-0.23,0-0.31,0v-2.5h-7.58v2.5c-0.08,0-0.23,0-0.31,0h-25.55c-0.55-1.48-1.95-2.58-3.59-2.58
                            c-2.11,0-3.83,1.72-3.83,3.83l0,0c0,2.11,1.72,3.83,3.83,3.83c1.64,0,3.05-1.09,3.59-2.58h16.17
                            c-11.41,4.84-19.69,15.55-21.1,28.36h-2.5v7.58h7.58v-7.58h-2.11c1.72-14.53,13.28-26.1,27.82-27.82v2.11h7.58v-2.11
                            c14.53,1.72,26.1,13.28,27.82,27.82h-2.11v7.58h7.58v-7.58h-2.5c-1.41-12.81-9.69-23.52-21.1-28.36h16.25
                            C660.32,243.41,661.73,244.43,663.37,244.43z"
                        />
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default InteractiveLogo;
