import { graphql } from "gatsby";
import React from "react";
import { SiteMetadataHead } from "../components/Shared/SiteMetadataHead";
import LandingPageTemplate from "../templates/LandingPage/LandingPageTemplate";

interface IIndexPageProps {
    data: {
        strapiLandingPage: GatsbyTypes.StrapiLandingPage;
    };
}
const IndexPage = ({ data }: IIndexPageProps) => {
    return <LandingPageTemplate {...data.strapiLandingPage} />;
};

export default IndexPage;

export const query = graphql`
    query LandingPage {
        strapiLandingPage {
            id
            seo {
                description
                title
                facebook {
                    description
                    title
                    url
                    type
                    image {
                        url
                    }
                }
                twitter {
                    card
                    description
                    title
                    site
                    image {
                        url
                    }
                }
            }
            themes {
                footer
                navbar
            }
            content {
                ... on StrapiComponentLandingBanner {
                    __typename
                    paragraph
                    heading
                    visible
                }
                ... on StrapiComponentLandingContact {
                    __typename
                    heading
                    cta
                    link
                    paragraph
                    visible
                }
                ... on StrapiComponentLandingHero {
                    __typename
                    subHeading
                    headingIntro
                    headingTagline {
                        tagline
                        id
                    }
                }
                ... on StrapiComponentLandingServices {
                    __typename
                    title
                    services {
                        ourServices {
                            heading
                            subHeading
                            imageSVG
                        }
                    }
                }
                ... on StrapiComponentLandingBlog {
                    __typename
                    title
                    blogs {
                        body
                    }
                }
            }
        }
    }
`;

export function Head({ data }: any) {
    const { seo } = data.strapiLandingPage;

    return (
        <SiteMetadataHead>
            <title id='site-title'>{seo.title}</title>
            <meta id='desc' name="description" content={seo.description} />
            <meta id='tw-card' name="twitter:card" content={seo.twitter.card } />
            <meta id='tw-site' name="twitter:site" content={seo.twitter.site} />
            <meta id='tw-creator' name="twitter:creator" content={seo.twitter.creator } />
            <meta id='tw-title' name="twitter:title" content={seo.twitter.title } />
            <meta id='tw-desc' name="twitter:description" content={seo.twitter.description } />
            <meta id='tw-img' name="twitter:image" content={seo.twitter.image.url } />
            <meta id='fb-type' property="og:type" content={seo.facebook.type } />
            <meta id='fb-title' property="og:title" content={seo.facebook.title } />
            <meta id='fb-desc' property="og:description" content={seo.facebook.description } />
            <meta id='fb-url' property="og:url" content={seo.facebook.url } />
            <meta id='fb-img' property="og:image" content={seo.facebook.image.url } />
        </SiteMetadataHead>
    );
}